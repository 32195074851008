const ti = require('technicalindicators');
ti.setConfig('precision', 6);

class MultiIndicatorCalculator {
    
    constructor(settings, initialTimestamps=[]) {
      this.sma1 = new ti.SMA(settings.SMA1);
      this.sma2 = new ti.SMA(settings.SMA2);
      this.ema = new ti.EMA(settings.EMA);
      this.rsi = new ti.RSI(settings.RSI);
      this.rsiw = new ti.RSI(settings.RSIw);  //week rsi
      this.macd = new ti.MACD(settings.MACD);
      this.bb = new ti.BollingerBands(settings.BB);
      this.timestamps = initialTimestamps;
    }
  
    nextValue(price, timestamp) {
      this.timestamps.push(timestamp);
      return {
        timestamp: timestamp,
        sma1: this.sma1.nextValue(price),
        sma2: this.sma2.nextValue(price),
        ema: this.ema.nextValue(price),
        rsi: this.rsi.nextValue(price),
        rsiw: this.rsi.nextValue(price),
        macd: this.macd.nextValue(price),
        bb: this.bb.nextValue(price)
      };
    }
  
    getResult() {
      return {
        sma1: this.sma1.getResult(),
        sma2: this.sma2.getResult(),
        ema: this.ema.getResult(),
        rsi: this.rsi.getResult(),
        rsiw: this.rsi.getResult(),
        macd: this.macd.getResult(),
        bb: this.bb.getResult(),
        timestamps: this.timestamps
      };
    }

    mapTimeStampToResults(allCandle, volumeData) {

       console.log('allCandle indi', allCandle, 'volumeData indi', volumeData)

        function assignHistColor(hist,previousHist){
          let hcolor = 0
          // console.log('hist', hist)
          // console.log('previousHist', previousHist)
          if (previousHist !== null) {
            if (hist >= 0) {
              if (hist < previousHist) hcolor = 1
            } else {
                hcolor = 2
                if (hist > previousHist) hcolor = 3
            }
          }
          return hcolor
        }

        function mapNewShapeMACD(macd){
          let previousHist = null;
          let reshapeMACD = macd.map((value, idx) => {
            const newMACD = [
              value[0],
              value[1].MACD,
              value[1].signal,
              value[1].histogram,
              assignHistColor(value[1].histogram,previousHist)
            ]
            previousHist = value[1].histogram
            return newMACD
          })
          console.log('macd beforemap', macd)
          console.log('reshapeMACD', reshapeMACD)
        return reshapeMACD
        }

        function findIndexOfUltraHighVolume(volume, v20_1, idx){
          if (volume > 2.2*v20_1){
            return idx
          }else{
            return null
          }
        }
        function getCalVolume(volume){
          // console.log('getCalVolume volume', volume)
          const volume20 = ti.SMA.calculate({period: 20, values: volume.map((value) => value[1])});
          const volumeWithTimestamp = volume.map((value, idx) => {
            return [
              value[0], 
              parseFloat(parseFloat(value[1]).toFixed(2)), 
              parseFloat(volume20[idx - 20 + 1]).toFixed(2),
              findIndexOfUltraHighVolume(value[1], volume20[(idx-1) - 20 + 1], idx)
            ]
          });
          console.log('volumeWithTimestamp', volumeWithTimestamp)
          return volumeWithTimestamp
        }

        function getCalRSI(rsi){
          const rsi_avg = ti.SMA.calculate({period: 14, values: rsi.map((value) => value[1])});
          const rsiWithTimestamp = rsi.map((value, idx) => {
            return [
              value[0], 
              value[1], 
              parseFloat(parseFloat(rsi_avg[idx - 14 + 1]).toFixed(2)),
            ]
          });
          console.log('rsiWithTimestamp', rsiWithTimestamp)
          return rsiWithTimestamp
        }

        function getCalRSIweek(rsi){
          const rsi_avg = ti.SMA.calculate({period: 70, values: rsi.map((value) => value[1])});
          const rsiWithTimestamp = rsi.map((value, idx) => {
            return [
              value[0], 
              value[1], 
              rsi_avg[idx - 70 + 1],
            ]
          });
          console.log('rsiWithTimestamp', rsiWithTimestamp)
          return rsiWithTimestamp
        }

        let sma1Results = this.sma1.getResult();
        let sma2Results = this.sma2.getResult();
        let emaResults = this.ema.getResult();
        let rsiResults = this.rsi.getResult();
        let rsiwResults = this.rsiw.getResult();
        let macdResults = this.macd.getResult();
        let bbResults = this.bb.getResult();
    
        let totalLength = this.timestamps.length;
    
        let sma1WithTimestamp = this.timestamps.slice(totalLength - sma1Results.length).map((value, idx) => [value, sma1Results[idx]]);
        let sma2WithTimestamp = this.timestamps.slice(totalLength - sma2Results.length).map((value, idx) => [value, sma2Results[idx]]);
        let emaWithTimestamp = this.timestamps.slice(totalLength - emaResults.length).map((value, idx) => [value, emaResults[idx]]);
        let rsiWithTimestamp = this.timestamps.slice(totalLength - rsiResults.length).map((value, idx) => [value, rsiResults[idx]]);
        let rsiwWithTimestamp = this.timestamps.slice(totalLength - rsiwResults.length).map((value, idx) => [value, rsiwResults[idx]]);
        let macdWithTimestamp = this.timestamps.slice(totalLength - macdResults.length).map((value, idx) => [value, macdResults[idx]]);
        let bbWithTimestamp = this.timestamps.slice(totalLength - bbResults.length).map((value, idx) => [value, bbResults[idx]]);
  
        return {
          candle: allCandle,
          sma1: sma1WithTimestamp,
          sma2: sma2WithTimestamp,
          ema: emaWithTimestamp,
          rsi: getCalRSI(rsiWithTimestamp),
          rsiw: getCalRSIweek(rsiwWithTimestamp),
          macd: mapNewShapeMACD(macdWithTimestamp),
          bb: bbWithTimestamp,
          volume: getCalVolume(volumeData)
        };
      }

    // getResultWithTimeStampPair() {
    //     return this.mapTimestampToResults();
    // }

  }

module.exports = MultiIndicatorCalculator;
