import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUseView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyView.vue')
  },
  {
    path: '/overlay',
    name: 'overlay',
    component: () => import(/* webpackChunkName: "about" */ '../views/OverlayTestView.vue')
  },
  {
    path: '/macd',
    name: 'macd',
    component: () => import(/* webpackChunkName: "about" */ '../views/MacdView.vue')
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: () => import(/* webpackChunkName: "about" */ '../views/ScannerView.vue')
  },
  {
    path: '/playbackv1',
    name: 'PlaybackV1',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/PlayBackV1.vue')
  },
  {
    path: '/marketsnapshot',
    name: 'MarketSnapshot',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketSnapshotView.vue')
  },
  {
    path: '/tradingsimulator',
    name: 'TradingSimulator',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/TradingSimulatorView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach((to, from, next) => {
//   // Dispatch the action to check the user's authentication status
//   store.dispatch('checkAuthState');

//   const isAuthenticated = store.getters.isAuthenticated;
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

//   if (requiresAuth && !isAuthenticated) {
//     // Store the route user is trying to access in vuex store before redirecting to login
//     store.commit('SET_ATTEMPTED_ROUTE', to.fullPath);
//     next({ name: 'Login' });
//   } else if (isAuthenticated && to.name === 'Login') {
//     // Retrieve the attempted route from vuex store if it exists
//     const attemptedRoute = store.state.attemptedRoute;
//     if (attemptedRoute) {
//       store.commit('CLEAR_ATTEMPTED_ROUTE');
//       next({ path: attemptedRoute });
//     } else {
//       next({ name: 'Home' });
//     }
//   } else {
//     next();
//   }
// });




export default router
