import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

import { getAuth, signInWithCustomToken } from 'firebase/auth';
import firebase from "@/firebase.js"

import DataCube from 'trading-vue-js/src/helpers/datacube.js'


import axios from 'axios'
const ti = require('technicalindicators');
Vue.use(Vuex)
Vue.use(VueCookies)


// const technicalindicators = require('technicalindicators');
const MultiIndicatorCalculator  = require('../utils/indi.js');

// var MacdHD_object = {
//   "name": "MacdHD",
//   "type": "MacdHD",
//   "data": [],
//   "settings": {
//       "histColors": [
//       "#35a776",
//       "#79e0b3",
//       "#e54150",
//       "#ea969e"
//       ]
//   }
// }



export default new Vuex.Store({
  state: {
    dark: false,
    theme:{
        dark:{
            backgroundColor: '#000000',
            textColor : '#ffffff'
        },	
        light:{
            backgroundColor: '#ffffff',
            textColor : '#000000'
        }
    },
    liffId: process.env.VUE_APP_LIFF_ID,
    platform: '',
    userProfile: null,
    userFbProfile: null,  //firebase user profile
    user: null,
    token: '',
    idToken:'',
    accessToken:'',
    errorCode: '',
    errorMessage: '',
    attemptedRoute: null,
    scanners:null,

    currentSymbol: null,
    dcube: null,

    calculatedIndicators: null,
    allCandleArray: null,
    allVolumeArray: null,
    allCandleIndex: null,
    countBars: 0,

    //add more  for chart simulator



  },
  getters: {
    isAuthenticated: state => !!state.user,
  },
  mutations: {
    setPlatform(state, platform) {
      state.platform = platform;
    },
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile;
    },
    setUserFbProfile(state, userFbProfile) {
      state.userFbProfile = userFbProfile;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setIdToken(state, idToken) {
      state.idToken = idToken;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setError(state, error) {
      state.errorCode = error.code;
      state.errorMessage = error.message;
    },
    SET_ATTEMPTED_ROUTE: (state, route) => {
      state.attemptedRoute = route;
    },
    CLEAR_ATTEMPTED_ROUTE: (state) => {
      state.attemptedRoute = null;
    },
    setScanner(state, scanners) {
      state.scanners = scanners;
    },

    SET_CURRENT_SYMBOL(state, payload) {
      state.currentSymbol = payload;
    },
    SET_DATA_CUBE(state, payload) {
      // console.log('SET_DATA_CUBE', payload)
      state.dcube = payload;
    },



    SET_CALCULATED_INDICATORS(state, payload) {
      state.calculatedIndicators = payload;
    },
    SET_ALL_CANDLE_ARRAY(state, payload) {
      state.allCandleArray = payload;
    },
    SET_ALL_VOLUME_ARRAY(state, payload) {
      state.allVolumeArray = payload;
    },
    SET_ALL_CANDLE_INDEX(state, payload) {  //จำนวน bar ทั้งหมดของกราฟ
      state.allCandleIndex = payload;
    },
    SET_COUNT_BARS(state, payload) {   //จำนวน bar ที่แสดงเพิ่มจนถึงปัจจุบัน
      state.countBars = payload;
    },

    
    

  }, 
  actions: {
    async initializeLiff({ commit, state }) {
      try {
        await liff.init({ liffId: state.liffId , withLoginOnExternalBrowser: true });
    
        if (liff.isInClient()) {
          commit('setPlatform', 'Opened in the LINE app');
    
          if (liff.isLoggedIn()) {
            try {
              const profile = await liff.getProfile();
              commit('setUserProfile', profile);
            } catch (error) {
              commit('setError', error);
            }
            commit('setIdToken', liff.getIDToken())
            commit('setAccessToken', liff.getAccessToken())
          }
        } else {
          const profileFetchAndCommit = async () => {
            try {
              const profile = await liff.getProfile();
              commit('setUserProfile', profile);
            } catch (error) {
              commit('setError', error);
            }
            commit('setIdToken', liff.getIDToken())
            commit('setAccessToken', liff.getAccessToken())
          }
    
          switch (liff.getOS()) {
            case 'ios':
              commit('setPlatform', 'Opened in an external mobile ios');

              //redirect to liff on APP
              const url1 = liff.permanentLink.createUrl();
              window.location.href = url1;

              if (!liff.isLoggedIn()) {  
                liff.login();
              } else {
                await profileFetchAndCommit();
              }
              break;
            case 'android':
              commit('setPlatform', 'Opened in an external mobile android');
                //redirect to liff on APP
                const url2 = liff.permanentLink.createUrl();
                window.location.href = url2;

              if (!liff.isLoggedIn()) {  
                liff.login();
              } else {
                await profileFetchAndCommit();
              }
              break;
            case 'web':
              commit('setPlatform', 'Opened in an external desktop browser');
              if (!liff.isLoggedIn()) {
                liff.login();
              } else {
                await profileFetchAndCommit();
              }
              break;
            default:
              commit('setPlatform', 'Platform unknown');
              if (!liff.isLoggedIn()) {
                liff.login();
              } else {
                await profileFetchAndCommit();
              }
              break;
          }
        }
      } catch (error) {
        commit('setError', error);
      }
    },
    async signInWithCustomToken({ commit, state }) {
      try {
        const res = await axios.post(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/createCustomToken?code=${state.idToken}`);
        console.log(res.data.firebase_token);
        const auth = getAuth(firebase);
        try {
          const userCredential = await signInWithCustomToken(auth, res.data.firebase_token);
          commit('setUser', userCredential.user);
          console.log(userCredential.user);
        } catch (error) {
          commit('setError', error);
        }
      } catch (error) {
        commit('setError', error);
      }
    },
    async signinWithLineID({ commit, state }) {
      
      const uid = state.userProfile.userId;
      console.log('uid', uid)
      console.log('json', JSON.stringify({ "uid" :uid }))

      let config = {
        method: 'post',
        // url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/apiUserSingin`,
        url: 'https://asia-southeast1-whalebots007.cloudfunctions.net/apiUserSingin',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : JSON.stringify({ "uid" :uid })
      };

      try {
        const res = await axios(config);
        if(res.data){
          console.log('after request to signin',res.data)
          Vue.$cookies.set('user', JSON.stringify(res.data), '3d'); // Sets a cookie that lasts for 1 day
          const cUser = Vue.$cookies.get('user');
          if(cUser){
            console.log('userId from cookie after set cookies:', cUser)
            commit('setUserFbProfile', cUser);
          }
          

        }
      } catch (error) {
        console.log('error',error);
        commit('setError', error);
      }
    },

    checkAuthState({ commit }) {   //ถ้าข้อมูลมีบน local storage และยังไม่หมดอายุจะดึงมาเอง แต่ถ้าไม่มีก็จะเข้าไปดึงจาก firebase
      const auth = getAuth();
      const user = auth.currentUser;
      commit('setUser', user);
    },
    async retrieveScanners({ commit, state }) {
      var data = JSON.stringify({"collection":"whalebotscanners","documentId":"scanners"});
  
      var config = {
        method: 'post',
        url: 'https://asia-southeast1-whalebots007.cloudfunctions.net/getDocumentById',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
  
      try {
        const response = await axios(config);
        console.log('response', response)
        console.log(JSON.stringify(response.data));
        commit('setScanner', response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData({ commit, state }, apiEndpoint) {
      try {
        function createDataCube(allData){
          console.log('createDataCube', allData)
          const candleData = allData.map(item => {
            const candle = [
                // item.datetime,
                new Date(item.datetime).getTime(),
                parseFloat(parseFloat(item.open).toFixed(2)),
                parseFloat(parseFloat(item.high).toFixed(2)),
                parseFloat(parseFloat(item.low).toFixed(2)),
                parseFloat(parseFloat(item.close).toFixed(2)),
                // parseFloat(item.volume)
            ]
            return candle
          });

          const volumeNoTimestamp= allData.map(item => {
            return parseFloat(item.volume)/1000000
          });


          const volumeData = allData.map(item => {
            const volume = [
                // item.datetime,
                new Date(item.datetime).getTime(),
                parseFloat(item.volume)/1000000,
            ]
            return volume
          });
          // console.log('volumeData vuex', volumeData)

        commit('SET_ALL_CANDLE_ARRAY', candleData);
        commit('SET_ALL_VOLUME_ARRAY', volumeData);

        // const closePrices = allData.map(item => {
        //   const close = [
        //     // new Date(item.datetime).getTime(),
        //     parseFloat(item.close),

        // ]
        // return close
        // });
        const close = allData.map(item => {
            return parseFloat(item.close)
        });
        const timestamps = allData.map(item => {
            return new Date(item.datetime).getTime()
        });
  

        // console.log('createDataCube close', close.slice(0,-1))

        // let close1 = close.slice(0,-1)
        // let timestamps1 = timestamps.slice(0,-1)
        // console.log('timestamps1', timestamps1.length, 'timestamps', timestamps.length)

        const settings = {
          SMA1: { period: 20, values: close },
          SMA2: { period: 100, values: close },
          EMA: { period: 14, values: close },
          RSI: { period: 14, values: close },
          RSIw: { period: 70, values: close },
          MACD: { SimpleMAOscillator:false,SimpleMASignal:false,fastPeriod: 12, slowPeriod: 26, signalPeriod: 9, values: close },
          BB: { period: 20, stdDev: 2, values: close }
        };
        
        const initialTimestamps = timestamps //[/* your initial timestamps here */];
        const calculator = new MultiIndicatorCalculator(settings, initialTimestamps);

        const finalResult = calculator.getResult();
        console.log('calculator.getResult()',finalResult);

        const allIndicatorWithTimestampPair = calculator.mapTimeStampToResults(candleData,volumeData);
        console.log('calculator.allIndicatorWithTimestampPair()',allIndicatorWithTimestampPair);

        commit('SET_CALCULATED_INDICATORS', allIndicatorWithTimestampPair);
        
        // // ... Add prices and timestamps here ...
        // console.log('nextValue( close.slice(-1), timestamps.slice(-1))', close.slice(-1)[0], timestamps.slice(-1)[0])
        // const result = calculator.nextValue(close.slice(-1)[0], timestamps.slice(-1)[0]);
        // console.log('nextValue( result', result)
        
        // const finalResultNext = calculator.getResult();
        // console.log('calculator.getResult() after pass new value',finalResultNext);


        // const barsback = 30
        const offset = 50 // to make sure we have enough data to calculate indicators
        const barsback = Math.floor(30 + Math.random() * (candleData.length - offset - 30));
        
        const startBarIndex = candleData.length - barsback;
        const initialData = candleData.slice(0, startBarIndex);
        console.log('createDataCube initialData', initialData)

        commit('SET_ALL_CANDLE_INDEX', candleData.length);
        commit('SET_COUNT_BARS', barsback);

         let dc =  new DataCube(
            ({
                // scripts: true,
                chart: { 
                  tf : "1D" ,
                  data: [] //candleData.slice(0,candleData.length-state.countBars),                   //initialData,
                },
                onchart: [
                ],
                offchart: [  
                  // MacdHD_object
                ],
                datasets: [
                  {
                    // name: "closePrice",
                    // type: "closePrice",
                    // id: "close",
                    // data: closePrices,
                    // settings: {
                    //   up: "#4caf50",
                    //   down: "#e91e63",
                    //   wick: "#757575",
                    //   border: "#2196f3",
                    //   wickBorder: "#2196f3",
                    //   wickUp: "#4caf50",
                    //   wickDown: "#e91e63",
                    //   barColors: true,
                    //   wickColors: true,
                    //   borderColors: true,
                    //   wickBorderColors: true,
                    //   wickUpColors: true,
                    //   wickDownColors: true,
                    //   borderUpColors: true,
                    //   borderDownColors: true,
                    //   borderUp: "#4caf50",
                    //   borderDown: "#e91e63",
                    //   barWidth: 0.8,
                    //   barSpacing: 0.2,
                    //   wickWidth: 0.8,
                    //   wickSpacing: 0.2,
                    // }
                  }
                ]
            })
        )
        return dc
        }
        

        const response = await axios.get(apiEndpoint);
        console.log('fetchData', response.data)
        const allData = response.data.values;

        // Create Data Cube (assuming it's a synchronous operation)
        const dc = createDataCube(allData);
        commit('SET_DATA_CUBE', dc);

      }catch (error) {
        console.log(error);
      }
    },
    async fetchSimData({ commit, state }, apiEndpoint) {
      try {
        function createDataCubeChartSim(allData){
          const candleData = allData.map(item => {
            const candle = [
                new Date(item.datetime).getTime(),
                parseFloat(parseFloat(item.open).toFixed(2)),
                parseFloat(parseFloat(item.high).toFixed(2)),
                parseFloat(parseFloat(item.low).toFixed(2)),
                parseFloat(parseFloat(item.close).toFixed(2)),
                parseFloat(item.volume)
            ]
            return candle
          });

          const volumeData = allData.map(item => {
            const volume = [
                new Date(item.datetime).getTime(),
                parseFloat(item.volume)/1000000,
            ]
            return volume
          });
         

        commit('SET_ALL_CANDLE_ARRAY', candleData);
        commit('SET_ALL_VOLUME_ARRAY', volumeData);

        // const closePrices = allData.map(item => {
        //   const close = [
        //     // new Date(item.datetime).getTime(),
        //     parseFloat(item.close),

        // ]
        // return close
        // });

        const close = allData.map(item => {
            return parseFloat(item.close)
        });
        const timestamps = allData.map(item => {
            return new Date(item.datetime).getTime()
        });
  

        const settings = {
          SMA1: { period: 20, values: close },
          SMA2: { period: 100, values: close },
          EMA: { period: 14, values: close },
          RSI: { period: 14, values: close },
          RSIw: { period: 70, values: close },
          MACD: { SimpleMAOscillator:false,SimpleMASignal:false,fastPeriod: 12, slowPeriod: 26, signalPeriod: 9, values: close },
          BB: { period: 20, stdDev: 2, values: close }
        };
        
        // const initialTimestamps = timestamps //[/* your initial timestamps here */];
        // const calculator = new MultiIndicatorCalculator(settings, initialTimestamps);

        // const finalResult = calculator.getResult();
        // console.log('calculator.getResult()',finalResult);

        // const allIndicatorWithTimestampPair = calculator.mapTimeStampToResults(candleData,volumeData);
        // console.log('calculator.allIndicatorWithTimestampPair()',allIndicatorWithTimestampPair);

        // commit('SET_CALCULATED_INDICATORS', allIndicatorWithTimestampPair);
        

        // const barsback = 30
        const offset = 50 // to make sure we have enough data to calculate indicators
        const barsback = Math.floor(30 + Math.random() * (candleData.length - offset - 30));
        
        const startBarIndex = candleData.length - barsback;
        const initialData = candleData.slice(0, startBarIndex);
        console.log('createDataCube initialData', initialData)

        commit('SET_ALL_CANDLE_INDEX', candleData.length);
        // commit('SET_COUNT_BARS', barsback);

        commit('SET_COUNT_BARS', 25);


         let dc =  new DataCube(
            ({
                // scripts: true,
                chart: { 
                  tf : "1D" ,
                  data: []    //initialData,
                },
                onchart: [
                ],
                offchart: [  
             
                ],
                datasets: [
                  // {
                  //   name: "closePrice",
                  //   type: "closePrice",
                  //   id: "close",
                  //   data: closePrices
                  // }
                ]
            })
        )
        return dc
        }
        const response = await axios.post(apiEndpoint);
        console.log('fetchSimData', response.data)
        const allData = response.data.values;
        const dc = createDataCubeChartSim(allData);
        commit('SET_DATA_CUBE', dc);

      }catch (error) {
        console.log(error);
      }
    }
  },
  modules: {
  }
})


