<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-img
        :src="require('@/assets/loading20.gif')"
        max-width="100px"
        contain
      />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
