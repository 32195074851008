<template>
	<v-container fluid class="fill-height" :style="{ 'background-color': colorTheme.backgroundColor, 'color':colorTheme.textColor }">
			<loading v-if="isLoading"></loading>

			<div class="centered-content">
				<div style="position: absolute; top: 0; right: 0; width: 80px; background-color: transparent;" class="pa-1 d-flex flex-column align-items-center">
					<v-avatar @click="showProfile" size="50" style="background-color: transparent;cursor: pointer;">
						<img :src="userPic"/>
					</v-avatar>
				</div>
				<!-- <p style="margin: 0; padding: 0; text-align: left;">{{ displayName }}</p> -->
				<img :src="logoSrc" alt="Logo" width="75" height="75" />
				<p>Welcome to Whalebots.ai</p>
				<!-- add four button here with flex, show one button per row if sm, show two button per row if md, show four button per row if lg -->
				<v-row class="mt-4">
					<v-col cols="12" sm="6" md="6">
						<v-btn @click="navigateToMarketSnapshot" block color="primary"><v-icon left>mdi-chart-line</v-icon>Market Snapshot</v-btn>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-btn block color="primary"><v-icon left>mdi-podium</v-icon>Ranking & Scanner</v-btn>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-btn block color="primary"><v-icon left>mdi-magnify</v-icon>Stock Insight</v-btn>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-btn @click="navigateToTradingSimulator" block color="primary"><v-icon left>mdi-gamepad</v-icon>Trading Simulator</v-btn>
					</v-col>
					<v-col cols="12" sm="6" md="6">
						<v-btn @click="navigateToStrategyPlayback" block color="primary"><v-icon left>mdi-skip-previous</v-icon>Strategy Playback</v-btn>
					</v-col>
				</v-row>
			</div>

			<v-card v-if="showProfileCard" style="position: absolute; top: 60px; right: 10px; width: 300px; height: 500px;">
				<v-card-title class="pb-0">
					Profile Info
					<v-btn icon small class="ml-1" @click="refreshUserData">
						<v-icon small>mdi-refresh</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<div>
						<v-chip
							class="ma-2"
							color="primary"
							label
							>
							<v-icon left>
								mdi-account-circle-outline
							</v-icon>
							{{  displayName }}
						</v-chip>
						<v-chip
							class="ma-2"
							color="orange"
							text-color="white"
							>
							<v-icon left>
								mdi-star
							</v-icon>
							{{ userPackage }} ({{ renewDate }})
						</v-chip>
					</div>
					<v-divider></v-divider>
					<v-chip class="ma-2" small color="#E92AA4" dark>
						<v-icon left> mdi-gift-outline</v-icon>
						<b>Bonus</b>
					</v-chip>
					<!-- Add other user info here... -->
			
					<v-data-table
						dense
						:headers="headers"
						:items="bonusItems"
						class="elevation-1"
						hide-default-footer
						height="350px"
					>
						<template v-slot:[`item.class`]="{ item }">
						<v-chip
							:color="getColor(item.class)"
							dark
						>
							{{ item.class }}
						</v-chip>
						</template>
					</v-data-table>
				
				</v-card-text>
			</v-card>
			
			<div style="position: absolute; bottom: 10px; right: 10px;">
				<a @click="navigateToTermOfUse" style="color: gray; margin-right: 10px; text-decoration: none;">Terms of Use</a>
				<a @click="navigateToPrivacyPolicy" style="color: gray; text-decoration: none;">Privacy Policy</a>
			</div>
	</v-container>
</template>


<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Loading from '@/views/LoadingView'
import TopBar from '@/components/TopBar';

export default {
	name: "Home",

	components: {
		Loading,
		TopBar
	},
	props:{
	},
	data() {
		return {
			logoSrc: require('@/assets/whale_logo_500.png'),
			isLoading: true,
			userPic: null,
			displayName: null,
			level: null,
			userPackage: null,
			dateLeft: null,
			headers: [
				{
					text: 'Features',
					align: 'start',
					sortable: false,
					value: 'service',
				},
				{ text: 'From', value: 'class',sortable: false},
				{ text: 'Expires', value: 'expires',sortable: false},
				
			],
			bonusItems: [],
			showProfileCard: false,
		};
	},
	beforeRouteUpdate(to, from, next) {

  	},
	created() {

	},
	computed: {
		...mapState(['dark','theme']),
		colorTheme() {
			return this.dark ? this.theme.dark : this.theme.light
		},	
		userProfile() {
      		return this.$store.state.userProfile;
    	},
		userFBProfile() {
	  		return this.$store.state.userFbProfile;
		},	
	},
	watch: {
    	userProfile(newVal, oldVal) {  //need this when main app.vue set userProfile state
      		if (newVal) {
        	// UserProfile has been updated, do something with the new value
			console.log('watch userProfile', newVal)
			this.userPic = newVal.pictureUrl
			this.displayName = newVal.displayName
      		}
    	},
		userFBProfile(newVal, oldVal) {  //need this when main app.vue set userProfile state
	  		if (newVal) {
				this.level = newVal.level
				this.userPackage = newVal.package
				const bonusObject = newVal.bonus
				console.log('bonusObject', bonusObject)
				this.bonusItems = Object.keys(bonusObject).map(key => {
					return {
						service: bonusObject[key].service,
						expires: bonusObject[key].expires,
						class: bonusObject[key].class,
						id: bonusObject[key].id
					};
				});

				const today = new Date();
				const targetDate = new Date(newVal.renew);

				const diffInTime = targetDate.getTime() - today.getTime();
				const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
				this.renewDate = diffInDays

				console.log('bonusItems', this.bonusItems)
				console.log('renewDate', this.renewDate)
			}
			// UserProfile has been updated, do something with the new value}
  		},
	},
	async created() {
		// await this.initializeLiff();
		// await this.signInWithCustomToken();
		this.isLoading = false
	},

	mounted() {

			// this.userPic = this.userProfile.pictureUrl //use when user click back on browser
			// this.displayName = this.userProfile.displayName //use when user click back on browser
	},
	methods: {
		...mapActions([
		'initializeLiff',
		'signInWithCustomToken'
		]),
		showProfile(){
			this.showProfileCard = !this.showProfileCard;
			// this.$router.push({ name: 'Profile' })
		},
		navigateToPrivacyPolicy() {
      		this.$router.push({ name: 'privacy' });
    	},
		navigateToTermOfUse() {
	  		this.$router.push({ name: 'terms' });
		},
		navigateToMarketSnapshot() {
			this.$router.push({ name: 'MarketSnapshot' });
		},
		navigateToTradingSimulator() {
			this.$router.push({ name: 'TradingSimulator' });
		},
		navigateToStrategyPlayback() {
			this.$router.push({ name: 'PlaybackV1' });
		},
		refreshUserData() {
			this.$store.dispatch('signinWithLineID');
		},
		getColor (packageclass) {
			if (packageclass == 'premium') return 'orange'
			else return 'gray'
		},
	},
	beforeDestroy() {

	},

};
</script>
<style scoped>
body, html {
    margin: 0;
    padding: 0;
}

.centered-content {
    font-family: Arial, sans-serif;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center; /* For horizontal centering */
    justify-content: center; /* For vertical centering */
    text-align: center; /* For text alignment within the elements */
    width: 100%; /* This ensures it takes the full width */
}
.v-container {
    margin: 0;
    padding: 0;
	overflow-y: hidden;
}
.user-profile {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}


</style>