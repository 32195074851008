<template>
    <v-app-bar
      app
      color="#0291EA"
      flat
      clipped-left
      dense
    > 
    <v-layout class="justify-space-between">
      <v-flex xs1>
        <v-app-bar-nav-icon class="nicon" color="white"></v-app-bar-nav-icon>
      </v-flex>
      <v-flex xs4></v-flex>
      <v-flex xs1>
        <div style="float:right;">
          <v-img
            alt="whalebots.ai"
            min-height="40"
            contain
            v-bind:src = "require(`@/assets/whalebotslogo.png`)"
            transition="scale-transition"
            @click="home"
            class="logo mt-1"
            style="cursor:pointer;"
          />
        </div>
      </v-flex>
      <v-flex xs3></v-flex>
      <v-flex xs2>    
        <!-- <div style="float:right">
            <v-btn
            @click="getSupport"
            text
            class="mt-1"
            >
            <span class="mr-2 txt-1">Support</span>
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>
        </div>  -->
      </v-flex>
    </v-layout>
    </v-app-bar>
</template>
<script>
// import { eventBus } from '../../main.js'
import { mapMutations } from 'vuex'
export default {
    name: 'TopBar',
    data(){
        return {
            //
            // drawer : true,
        }
    },
    methods: {
      ...mapMutations({
        //   toggleToolBar: 'toggleToolbar'
      }),
      home(){
        this.$router.push({"name":'home'}).catch(err => {err});
      },
    //   getSupport(){
    //     this.$router.push({"name":"Support"}).catch(err=> {err});
    //   }
      // showToolBarOnMobile() {
      //     this.$store.state.showToolBar = !this.$store.state.showToolBar;
      //     console.log('showToolbar:', !this.$store.state.showToolBar)
      //     // this.drawer = !this.drawer;
      //     // eventBus.showToolBar(this.drawer);
      // }

      
    }
}
</script>

<style scoped>
.txt-1 {
  font-size: calc(7px + 0.3vw);
}
.logo {
  width: calc(25px + 0.3vw);
}
.nicon {
  height: calc(7px + 0.3vw);
}
</style>