<template>
	<v-app>
		<!-- <ToolBar/><TopBar/> -->
		<v-main>
			<!-- <router-view /> -->
			<v-container fluid class="fill-height" v-if="showNeedOpenWithLINE">
				<div class="centered-content">
					<p>To get full access, please open this link from the LINE app</p>
					<p><a href="https://liff.line.me/2000078438-bYLZxakw">https://liff.line.me/2000078438-bYLZxakw</a></p>
					<!-- <p>Navigator : {{ navigator }}</p> -->
				</div>
			</v-container>

			<router-view v-if="!showNeedOpenWithLINE"/> 

			<!-- for debug -->
			<!-- <v-container fluid class="fill-height" v-if="!showNeedOpenWithLINE">
				<v-row class="fill-height" align-items="center" justify="center">
				<v-col cols="12" class="text-center">
					<p>{{ liffobject  }}</p>
					<p>isLiffLogin : {{isLiffLogin }}</p>
					<p>LiffOS : {{LiffOS }}</p>
					<p>{{ deviceDetect }}</p>
					<p>liffID : {{ liffID }}</p>
					<p>Navigator : {{ navigator }}</p>
					<p>User Profile : {{ userProfile }}</p>	
				</v-col>
				</v-row>
			</v-container> -->

		</v-main>
	</v-app>
</template>

<script>
import { set } from 'vue';

// import TopBar from './components/TopBar';
import { mapState, mapMutations, mapActions } from "vuex";

export default {
	name: 'App',
	components: {
    // TopBar,
  },
	data: () => ({
		
		liffobject: null,
		isLiffLogin: false,
		LiffOS: null,

		showNeedOpenWithLINE: true,

		deviceDetect: null,
		liffID: null,
		navigator: null,
		userProfile:null
	}),
	methods:{
		...mapMutations(['setPlatform','setUserProfile','setUserFbProfile','setError','setIdToken','setAccessToken']),
		...mapActions(['signinWithLineID']),
		async saveUserProfile(liff){
			const profile = await liff.getProfile();
			this.userProfile = profile;
			console.log('saved profile',profile)
			this.setUserProfile(profile)
			this.$store.commit('setUserProfile', profile);
		},
		async signinUser(){
			console.log('need to signinWithLineID')
			await this.signinWithLineID()
		}
	},
	computed: {
		...mapState(['userFbProfile']),

		},
	async mounted() {
		const devices = this.$device;
		console.log('deviceDetect',devices)
		this.deviceDetect = devices;
		try {
			if(this.deviceDetect.mobile == true){
				await liff.init({ liffId: process.env.VUE_APP_LIFF_ID , withLoginOnExternalBrowser: false });
				if (liff.isInClient()) {
					this.showNeedOpenWithLINE = false;
					this.navigator = navigator.userAgent
					this.liffobject = liff;
					this.isLiffLogin = liff.isLoggedIn();
					this.LiffOS = liff.getOS()
					await this.saveUserProfile(liff)

				}else{
					switch(liff.getOS()){
					case 'ios':
						console.log('ios')
						window.location.href = "line://app/"+ process.env.VUE_APP_LIFF_ID  
						this.navigator = navigator.userAgent	
						await this.saveUserProfile(liff)
						break;
					case 'android':
						window.location.href = "line://app/"+ process.env.VUE_APP_LIFF_ID  
						this.navigator = navigator.userAgent
						await this.saveUserProfile(liff)
						break;
					default:
					this.showNeedOpenWithLINE = true;
						console.log('default')
					}
				}
			}else if(this.deviceDetect.ipad == true){
				await liff.init({ liffId: process.env.VUE_APP_LIFF_ID , withLoginOnExternalBrowser: false });
				if (liff.isInClient()) {
					this.showNeedOpenWithLINE = false;
					this.navigator = navigator.userAgent
					await this.saveUserProfile(liff)
					
				}else{
					this.showNeedOpenWithLINE = true;
					window.location.href = "line://app/"+ process.env.VUE_APP_LIFF_ID  
					this.navigator = navigator.userAgent				
				}
				this.liffobject = liff;
				this.isLiffLogin = liff.isLoggedIn();
				this.LiffOS = liff.getOS();
			}else if (this.deviceDetect.windows == true){
				await liff.init({ liffId: process.env.VUE_APP_LIFF_ID , withLoginOnExternalBrowser: true });
				this.showNeedOpenWithLINE = false;
				this.navigator = navigator.userAgent
				this.liffobject = liff;
				this.isLiffLogin = liff.isLoggedIn();
				this.LiffOS = liff.getOS();
				await this.saveUserProfile(liff)

				this.setPlatform('windows')

			}else if (navigator.userAgent.includes('Macintosh') == true){
				await liff.init({ liffId: process.env.VUE_APP_LIFF_ID , withLoginOnExternalBrowser: true });
				this.showNeedOpenWithLINE = false;
				this.navigator = navigator.userAgent
				this.liffobject = liff;
				this.isLiffLogin = liff.isLoggedIn();
				this.LiffOS = liff.getOS();

				this.setPlatform('Macintosh')

			}else{
				this.showNeedOpenWithLINE = true;
			}
             
			//check from cookies
			console.log('after liff init check user FB profile bofore singin',this.userFbProfile) //should be null
			const user = this.$cookies.get('user')
			console.log('check userId from cookies if need to sign-in',user)
			if(!user){
				await this.signinUser()
			}else{
				console.log('user cookies valid and set to store',user)
				this.setUserFbProfile(user)
			}

		}catch (error) {
			console.log('error',error)
			// this.setError(error)
		}
	}
};
</script>

<style scoped>
.centered-content {
    font-family: Arial, sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center; /* For horizontal centering */
    justify-content: center; /* For vertical centering */
    text-align: center; /* For text alignment within the elements */
    width: 100%; /* This ensures it takes the full width */
}
</style>
